import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['toggle', 'overlay'];

    connect () {
        this.toggleTarget.addEventListener('click', () => {
            document.body.classList.toggle('has-overlay')
        });
    }
}
