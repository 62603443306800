import { Controller } from '@hotwired/stimulus';
import * as Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css'

export default class extends Controller {
    static targets = ['marker'];
    static values = {
        lat: {
            type: Number,
            required: true,
        },
        lng: {
            type: Number,
            required: true
        },
        zoom: {
            type: Number,
            default: 16,
        },
    };

    connect () {
        this.map = Leaflet.map(this.element).setView([this.latValue, this.lngValue], this.zoomValue);

        Leaflet.tileLayer('https://tile.osm.ch/switzerland/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer noopener">OpenStreetMap</a>'
        }).addTo(this.map);

        this.map.attributionControl.setPrefix(false);

        this.markerTargets.forEach((el) => {
            const size = el.getBoundingClientRect();
            Leaflet.marker([el.dataset.leafletLatValue, el.dataset.leafletLngValue], {
                icon: Leaflet.divIcon({
                    iconSize: [size.width, size.height],
                    className: el.className,
                    html: el.innerHTML,
                })
            }).addTo(this.map);
        })
    }

    disconnect () {
        this.map?.remove();
    }
}
