import { Controller } from '@hotwired/stimulus';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';

export default class extends Controller {

    connect () {
        const link = this.element.querySelector('a');

        this.$glightbox = GLightbox({ elements: [link] });

        link.addEventListener('click', (event) => {
            event.preventDefault();
            this.$glightbox.open();
        })
    }

    disconnect () {
        if (this.$glightbox) {
            this.$glightbox.destroy();
        }
    }
}
