import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['pagination', 'slider', 'thumbs', 'thumbsPagination'];

    static values = {
        effect: {
            type: String,
            default: 'slide'
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
    };

    async connect () {
        const { default: Swiper } = await import('swiper');
        await import('swiper/scss');

        const options = {
            modules: [],
            effect: this.effectValue,
            loop: true,
        };

        if (this.effectValue === 'fade') {
            const { EffectFade } = await import('swiper/modules');
            await import('swiper/scss/effect-fade');
            options.modules.push(EffectFade);
        }

        if (this.autoplayValue) {
            const { Autoplay } = await import('swiper/modules');
            await import('swiper/scss/autoplay');
            options.modules.push(Autoplay);
            options.autoplay = this.autoplayValue;
        }

        if (this.hasPaginationTarget) {
            const { Pagination } = await import('swiper/modules');
            await import('swiper/scss/pagination');
            options.modules.push(Pagination);
            options.pagination = {
                el: this.paginationTarget,
                clickable: true,
            };
        }

        if (this.hasThumbsTarget) {
            const thumbsOptions = {
                modules: [],
                breakpoints: {
                    760: {
                        slidesPerView: 3,
                    }
                }
            };

            if (this.hasThumbsPaginationTarget) {
                const { Pagination } = await import('swiper/modules');
                await import('swiper/scss/pagination');
                thumbsOptions.modules.push(Pagination);
                thumbsOptions.pagination = {
                    el: this.thumbsPaginationTarget,
                    clickable: true,
                };
            }

            this.thumbsSlider = new Swiper(this.thumbsTarget, thumbsOptions);

            const { Thumbs } = await import('swiper/modules');
            await import('swiper/scss/thumbs');
            options.modules.push(Thumbs);
            options.thumbs = {
                swiper: this.thumbsSlider,
            };
        }

        this.swiper = new Swiper(this.hasSliderTarget ? this.sliderTarget : this.element, options);
    }

    disconnect () {
        this.swiper?.destroy();
    }
}
